table.pagination-subject thead tr th[data-field] {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}
table.pagination-subject thead tr th[data-field].sorting-dir-asc {
  background-image: url(../images/Icons/Drop-Down-Zuklappen.svg);
}
table.pagination-subject thead tr th[data-field].sorting-dir-desc {
  background-image: url(../images/Icons/Drop-Down-Aufklappen.svg);
}
table.pagination-subject thead tr th[data-field].sort-loaded {
  cursor: pointer;
}
table.pagination-subject thead tr.filter td {
  padding: 0;
  background-color: rgba(22, 132, 179, 0.1);
}
table.pagination-subject thead tr.filter td .form-control {
  border: none !important;
  padding: 0 0.25em 0 0.5rem;
  height: auto;
  font-size: 14px;
  background-color: transparent;
}
table.pagination-subject thead tr.filter td.hasfilters {
  background-color: #F0CE70;
}
table.pagination-subject thead tr.filter td.hasfilters .pagination-filter {
  background-color: rgba(255, 255, 255, 0.75);
}

.data-pagination-loaded .pagination-header {
  z-index: 5;
  position: sticky;
  top: 50px;
  background-color: white;
  margin-bottom: 20px;
}
.data-pagination-loaded .pagination-actions {
  float: right;
}
.data-pagination-loaded .pagination-control .pagination-sorting-by {
  width: 200px;
}
.data-pagination-loaded .pagination-control .pagination-sorting-dir {
  width: 130px;
}
.data-pagination-loaded .pagination-control .input-group {
  margin-right: 20px;
}
.data-pagination-loaded .pagination-control .btn {
  margin: 0;
}

select.numeric,
select.numeric option {
  text-align: right;
}