#flashbag {
  position: fixed;
  bottom: 70px;
  left: 50%;
  right: 50%;
  width: 0;
  height: 0;
  background-color: transparent;
  display: block;
  z-index: 2001;
}
#flashbag .flashbag-container {
  width: 930px;
  margin-left: -465px;
  position: absolute;
  bottom: 0;
}
#flashbag .flashbag-container div {
  width: 100%;
  max-width: 100vw;
  display: block;
  margin: 0.25em auto;
  color: #000000;
  font-weight: bold;
  opacity: 0 !important;
  transition: opacity 1s;
  box-shadow: 0px 0px 2px 2px #ffffff;
}
#flashbag .flashbag-container div.come {
  display: block;
  opacity: 1 !important;
  transition: opacity 1s;
}
#flashbag .flashbag-container div.leave {
  opacity: 0 !important;
  transition: opacity 1s;
}
#flashbag .flashbag-container div .close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: black;
  opacity: 1;
}
#flashbag .flashbag-container div .close.hover {
  color: black;
}
#flashbag:empty {
  display: none !important;
}